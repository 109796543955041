import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import BookingDetails from "../../containers/BookingDetails/BookingDetails";
import { ReactComponent as SuccessIcon } from "../../assets/images/success.svg";
import { useTranslation } from "react-i18next";
import styles from "../SuccessResult/SuccessResult.module.scss";

const Return = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [status, setStatus] = useState<string | null>(null);
  const [reservationId, setReservationId] = useState<string | null>(null);
  const [showCongratulations, setShowCongratulations] = useState(true);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    fetch(`${process.env.REACT_APP_API_URL}/api/stripe/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setReservationId(data.reservationId);
      });

    const timer = setTimeout(() => {
      setShowCongratulations(false);
      setShowDetails(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  if (status === "complete") {
    return (
      <div className={styles.successResult}>
           {showCongratulations && (
            <SuccessIcon/>
           )}
        {showCongratulations && (
          
          <p className="mt-5 ">{t("app.bookFlight.result.success.title", "Congratulations!")}</p>
        )}
        {showCongratulations && (
        <p>{t("app.bookFlight.result.success.subtitle", "Your flight has been booked.")}</p>
        )}

        { reservationId && (
          <div className={styles.bookingDetailsWrapper}>
            <BookingDetails reservationId={reservationId} />
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default Return;
