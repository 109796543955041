import React, { useState, useEffect } from "react";
import { Elements, EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import stripePromise from "./StripeConfig";
import { Link, useLocation } from "react-router-dom";



const CheckoutForm = ({ clientSecret }: { clientSecret: string }) => {
  
    return (
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{ clientSecret }}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    );
  };

export default CheckoutForm;
