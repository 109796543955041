import React, {useEffect, useState} from "react";
import './BookingDetails.scss';
import Details from "../../components/Details/Details";
import {ReservationService} from "../../actions";
import {useHistory, useParams} from "react-router";
import {ReactComponent as Arrow} from "../../assets/images/arrow-left.svg";
import {useTranslation} from "react-i18next";
import MyBooking from "../../components/MyBooking/MyBooking";
import BookingDetailsPassenger from "../../components/BookingDetailsPassenger";
import classNames from "classnames";
import SideOffers from "../../components/SideOffers";
import Invoice from "../../components/Invoice/Invoice";
import {matchPath} from "react-router";


const BookingDetails = ({ reservationId }: { reservationId: string | null }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [reservation, setReservation] = useState<any>(null);
  const [expandedRow, setExpandedRow] = useState<any>(null);

  const isShowInvoice = !!matchPath(
    window.location.pathname,
    "/bookings/:reservationId/invoice"
  );

  useEffect(() => {
    if (reservationId) {
      ReservationService.getReservationById(reservationId).then((res) => {
        setReservation(res);
      });
    }
  }, [reservationId]);

  return (
    <div className="booking-details-container">
      {isShowInvoice && <Invoice reservation={reservation} showDetails={true} />}
      {!isShowInvoice && (
        <>
         {reservation && <div
            className="booking-details-title"
            onClick={() => history.push("/bookings")}
          >
             {t("app.bookingDetails.title", "Booking Details")}
          </div>}
          <div className="row mt-5" style={{ display: "flex", justifyContent: "center" }}>
            <div className="col-md-12 col-lg-9">
              {reservation && <MyBooking onClick={() => null} reservation={reservation} />}
              {reservation?.travelers.map((traveler: any, index: number) => (
                <div className="booking-details-cards-container">
                  <BookingDetailsPassenger
                    key={traveler.id}
                    expanded={expandedRow === index}
                    passenger={traveler}
                    index={index}
                    onClick={() => setExpandedRow(expandedRow === index ? null : index)}
                  />
                  <div className={classNames("expandable-row", { expanded: index === expandedRow })}>
                    <Details traveler={traveler} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
  
};

export default BookingDetails;


