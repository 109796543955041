import React from "react";
import "./myBooking.scss";
import { CalendarOutlined } from "@ant-design/icons/lib";

interface Props {
  reservation: any;
  onClick: any;
}

const MyBooking = (props: Props) => {
  const { reservation, onClick } = props;

  const traveler = reservation.travelers[0];
  const returningFlight = traveler.returningFlight;
  return (
    <div className="booking" onClick={onClick}>
      <div className="booking-flight">
        <div style={{ display: "flex" }}>
          <CalendarOutlined />
          <div style={{display: 'flex'}}>
              <div>
                  <b  style={{fontSize:"15px"}} >Departure: </b>
                  <div style={{fontSize:"15px"}}>{traveler?.departureFlight.dateOfDeparture} - {traveler?.departureFlight.timeOfDeparture} {" "}</div>
              </div>
              <div style={{marginLeft: '2rem'}}>

                  {returningFlight ? (<>
                      <b style={{fontSize:"15px"}}>Return: </b>
                      <div style={{fontSize:"15px"}}> {returningFlight?.dateOfDeparture} - {returningFlight?.timeOfDeparture} {" "}</div>
                      </>
                  ) : (
                      <></>
                  )}
              </div>
          </div>
        </div>
        <h2 style={{marginTop:"10px"}}>
          {traveler?.departureFlight.departureAirport} -
          {traveler?.departureFlight.arrivalAirport}
          {returningFlight ? `- ${returningFlight?.arrivalAirport}` : ''}
        </h2>
      </div>
      {/*<div className="confirmation-code">*/}
      {/*  Confirmation Code<span>{reservation.confirmationCode}</span>*/}
      {/*</div>*/}
    </div>
  );
};

export default MyBooking;
