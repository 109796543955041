import React, {useEffect, useState, useRef} from "react";
import BookingInformation from "./components/BookingInformation";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";
import {getQueryParameterByName} from "../../../utils";
import Spinner from "../../../components/Spinner/Spinner";
import classNames from "classnames";
import {ReactComponent as Arrow} from "../../../assets/images/right-arrow.svg";
import {useTranslation} from "react-i18next";
import PaymentMethods from "../PaymentMethods/PaymentMethods";
import CheckoutForm from "../../../components/Stripe/Checkout";


const Overview = ({ clientSecret }: { clientSecret: string | null }) => {
 
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const {t} = useTranslation();

  const departureAirport = getQueryParameterByName("from");
  const arrivalAirport = getQueryParameterByName("to");
  const departureDate = getQueryParameterByName("departure");
  const returnDate = getQueryParameterByName("return");
  const adults = getQueryParameterByName("adults");
  const children = getQueryParameterByName("children");
  const infants = getQueryParameterByName("infants");
  const source = getQueryParameterByName("source");
  const language = getQueryParameterByName("language");

  const [loading, setLoading] = useState(false);

  // const price = useSelector(
  //     (state: AppState) => state.selectedFlights.pricePreview
  // );
  // const accountInfoState = useSelector(
  //     (state: AppState) => state.accountInfo.accountInfo
  // );
  //
  // const departureFlight = useSelector(
  //     (state: AppState) => state.selectedFlights.departureFlight
  // );
  // const returnFlight = useSelector(
  //     (state: AppState) => state.selectedFlights.returnFlight
  // );

  // const nextStep = () => {
  //   history.push({
  //     pathname: "/booking/payment-methods",
  //     search: `?from=${departureAirport}&to=${arrivalAirport}&departure=${departureDate}${
  //         returnDate ? "&return=" + returnDate : ""
  //     }&adults=${adults}${children ? "&children=" + children : ""}${
  //         infants ? "&infants=" + infants : ""
  //     }${source ? "&source=" + source : ""}${language ? "&language=" + language : ""}`,
  //   });
  // };

  const onGoBackClick = () => {
    history.push({
      pathname: "/booking/passengers",
      search: `?from=${departureAirport}&to=${arrivalAirport}&departure=${departureDate}${
          returnDate ? "&return=" + returnDate : ""
      }&adults=${adults}${children ? "&children=" + children : ""}${
          infants ? "&infants=" + infants : ""
      }${source ? "&source=" + source : ""}${language ? "&language=" + language : ""}`,
    });
  };
 
  if (!clientSecret) {
    return <div>Loading payment details...</div>;
  }
  return (
      <div className="overview-container">
        <div className={classNames("overview", {["opacity-60"]: loading})}>
          <div className="flights-page-back">
            <div className="flights-back-arrow" onClick={onGoBackClick}>
              <Arrow/>
              {t("app.bookFlight.header.goBack", "Go back")}
            </div>
          </div>
          {loading && <Spinner center/>}
          <h2 style={{marginBottom: "3.3rem", fontSize: "30px", color: "#000"}}>
            {t("app.bookFlight.overview.title", "Payment Confirmation")}
          </h2>
          <BookingInformation/>
          <CheckoutForm clientSecret={clientSecret} />
        </div>
      </div>
  );
};

export default Overview;
