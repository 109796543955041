import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router";
import Passengers from "./Passengers/Passengers";
import Flights from "./Flights/Flights";
import Payment from "./Payment/Payment";
import Overview from "./Overview/Overview";
import { Steps } from "antd";
import "./BookFlight.scss";
import { getQueryParameterByName } from "../../utils";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { useTranslation } from "react-i18next";
import axios from "axios";

const { Step } = Steps;

const BookFlight = (props: any) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const accessToken = useSelector((state: AppState) => state.auth.accessToken);
  const passengers = useSelector((state: AppState) => state.passengers.passengers);

  const currentStep = () => {
    if (location.pathname === "/booking/flights") return 0;
    if (location.pathname === "/booking/passengers") return 1;
    return 2;
  };

  useEffect(() => {
    if (currentStep() === 2 && !clientSecret) {
      // Automatically generate client secret when reaching the Overview step
      const generateClientSecret = async () => {
        try {
          const payload = {
            travelers: passengers,
            contactNumber: passengers[0]?.phoneNumber || "",
            fullName: `${passengers[0]?.name || ""} ${passengers[0]?.surname || ""}`,
            email: passengers[0]?.email || "",
            source: "WEB",
            paymentType:"STRIPE"
          };

          const reservationResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/v1/reservations/checkout`,
            payload,
            { headers: { "Content-Type": "application/json" } }
          );
          let reservationId;
          if (typeof reservationResponse.data === "string") {
            const parsedData = JSON.parse(reservationResponse.data);
            reservationId = parsedData.proceedOrderUrl;
          } else {
            reservationId = reservationResponse.data.proceedOrderUrl;
          }
          const sanitizedReservationId = reservationResponse.data.proceedOrderUrl.replace(/"/g, "").trim();

          const stripeResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/stripe/create-checkout-session?reservationId=${sanitizedReservationId}`
          );

          setClientSecret(stripeResponse.data.clientSecret);
        } catch (error) {
          console.error("Error generating client secret:", error);
        }
      };

      generateClientSecret();
    }
  }, [location.pathname, passengers, clientSecret]);

  return (
    <div className="book-flight-container">
      <div className="book-flight-steps">
        <Steps direction="horizontal" current={currentStep()}>
          <Step title={t("app.bookFlight.flights.steps.title", "Flights")} />
          <Step title={t("app.bookFlight.flights.steps.title1", "Passengers")} />
          <Step title={t("app.bookFlight.flights.steps.title2", "Payment")} />
        </Steps>
      </div>
      <div className="book-flight-content">
        <Switch>
          <Route path="/booking/flights" render={() => <Flights />} />
          <Route path="/booking/passengers" render={() => <Passengers />} />
          <Route
            path="/booking/overview"
            render={() => <Overview clientSecret={clientSecret} />}
          />
          <Route path="/booking/payment" component={Payment} />
        </Switch>
      </div>
    </div>
  );
};

export default BookFlight;
